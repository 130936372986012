var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inventoryLabResultCreate"},[_c('v-breadcrumbs',[_c('v-breadcrumbs-item',{attrs:{"exact":true,"to":{ name: 'home', params: {} }}},[_vm._v("Home")]),_c('v-breadcrumbs-divider',[_vm._v("/")]),_c('v-breadcrumbs-item',{attrs:{"exact":true,"to":{ name: 'inventoryLabResult', params: {} }}},[_vm._v(" Inventory Lab Result ")]),_c('v-breadcrumbs-divider',[_vm._v("/")]),_c('v-breadcrumbs-item',{attrs:{"to":{ name: 'inventoryCoaCheck', params: {} }}},[_vm._v(" Coa Check ")])],1),_c('v-card',{staticClass:"py-10"},[_c('v-card-title',[_vm._v("Choose a product to check inventories without COA")]),_c('v-card-text',[_c('product-select',{attrs:{"manufactured-only":"","return-object":""},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.goBack}},[_vm._v(" Back ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading},on:{"click":_vm.findWithoutCoa}},[_vm._v("Find Inventory Without CoA")]),_c('v-spacer')],1)],1),_c('v-sheet',{staticClass:"my-10"},[_c('v-data-table',{attrs:{"items":_vm.matchingResults,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"color":"primary"},on:{"click":function($event){_vm.lookupInventory = true;
            _vm.selectedRow = item;
            _vm.selectedInventory = item.id;}}},[_vm._v(_vm._s(item.id))])]}},{key:"item.unitAvailable",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item.unitAvailable)+" ")])]}}],null,true)},[_c('v-list',{staticClass:"pa-5"},_vm._l((item.unitDetails),function(unit,index){return _c('v-list-item',{key:unit.id},[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(index + 1)+". "+_vm._s(_vm._f("formatNumber")(unit.amountPerUnit))+" "+_vm._s(unit.unitMeasure)+" ("+_vm._s(unit.code)+") ")])],1)}),1)],1)]}}])})],1),_c('inventory-lookup',{attrs:{"activated":_vm.lookupInventory},on:{"update:activated":function($event){_vm.lookupInventory=$event}},model:{value:(_vm.selectedInventory),callback:function ($$v) {_vm.selectedInventory=$$v},expression:"selectedInventory"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }