<template>
  <div class="inventoryLabResultCreate">
    <v-breadcrumbs>
      <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
        >Home</v-breadcrumbs-item
      >
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item
        :exact="true"
        :to="{ name: 'inventoryLabResult', params: {} }"
      >
        Inventory Lab Result
      </v-breadcrumbs-item>
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item :to="{ name: 'inventoryCoaCheck', params: {} }">
        Coa Check
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-card class="py-10">
      <v-card-title
        >Choose a product to check inventories without COA</v-card-title
      >
      <v-card-text>
        <product-select manufactured-only v-model="product" return-object />
      </v-card-text>
      <v-card-actions>
        <v-btn @click="goBack"> Back </v-btn>
        <v-spacer />
        <v-btn color="primary" :disabled="loading" @click="findWithoutCoa"
          >Find Inventory Without CoA</v-btn
        >
        <v-spacer />
      </v-card-actions>
    </v-card>

    <v-sheet class="my-10">
      <v-data-table :items="matchingResults" :headers="headers">
        <template v-slot:item.id="{ item }">
          <a
          color="primary"
            @click="
              lookupInventory = true;
              selectedRow = item;
              selectedInventory = item.id;
            "
            >{{ item.id }}</a
          >
        </template>
        <template v-slot:item.unitAvailable="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" text color="primary">
                {{ item.unitAvailable }}
              </v-btn>
            </template>
            <v-list class="pa-5">
              <v-list-item
                v-for="(unit, index) in item.unitDetails"
                :key="unit.id"
              >
                <v-list-item-subtitle>
                  {{ index + 1 }}. {{ unit.amountPerUnit | formatNumber }}
                  {{ unit.unitMeasure }} ({{ unit.code }})
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-sheet>

    <inventory-lookup
      :activated.sync="lookupInventory"
      v-model="selectedInventory"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
const ProductSelect = () => import("@/components/product/ProductSelect.vue");
const InventoryLookup = () =>
  import("@/components/inventory/InventoryLookup.vue");

export default {
  name: "inventoryCoaCheck",
  data: () => {
    return {
      matchingResults: [],
      product: null,
      loading: false,
      headers: [
        // {
        //   text: "Document No",
        //   value: "documentNo",
        //   align: "start",
        //   sortable: true,
        // },
        {
          text: "ID",
          value: "id",
          align: "start",
          sortable: true,
        },
        {
          text: "Stock Time",
          value: "stockTime",
          align: "start",
          sortable: true,
        },
        {
          text: "Quality Available",
          value: "quantityAvailable",
          align: "start",
          sortable: true,
        },
        {
          text: "Unit Available",
          value: "unitAvailable",
          align: "start",
          sortable: false,
        },
      ],
      lookupInventory: false,
      selectedInventory: null,
      selectedRow: null
    };
  },
  created() {
    this.$emit("showParent", false);
  },
  components: {
    ProductSelect,
    InventoryLookup,
  },
  methods: {
    ...mapActions("inventoryLabResult", [
      "createInventoryLabResult",
      "createAllInventoryLabResults",
    ]),
    ...mapActions("labElement", ["fetchLabElements"]),
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),
    findWithoutCoa() {
      if (!this.product) {
        this.addErrorMessage("Please choose a product first");
        return;
      }

      const params = {
        productId: this.product.id,
      };
      this.$axios
        .get("/inventory-lab-result/without-coa", { params })
        .then((response) => {
          if (response) {
            this.matchingResults = response.data;
          }
        })
        .catch((error) => {
          this.addErrorMessage("Server response with error " + error);
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
